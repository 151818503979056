import { Injectable } from '@angular/core';
import { isAnalyticsError } from '../../../firebase';
import { ErrorHandlerMapper } from '../error-handler.models';

@Injectable({ providedIn: 'root' })
export class AnalyticsCookiesNotEnabledMapper implements ErrorHandlerMapper {
  mapError(error: any) {
    if (
      isAnalyticsError(error) &&
      error.code.includes('analytics/cookies-not-enabled')
    ) {
      return false;
    }
    return error;
  }
}
