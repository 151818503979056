import { Injectable, InjectionToken, inject } from '@angular/core';
import { LogicError } from '../../errors';
import { CustomSurveysService } from './custom-surveys.service';
import { GenericSurveysService } from './generic-surveys.service';
import { SurveysService } from './surveys.service';
import { TherapeuticSurveysService } from './therapeutic-surveys.service';

export type ProjectContext = 'wellness' | 'medical';

export const PROJECT_CONTEXT = new InjectionToken<ProjectContext>(
  'ProjectContext'
);

export function provideProjectContext(projectContext: ProjectContext) {
  return { provide: PROJECT_CONTEXT, useValue: projectContext };
}

export type SurveyContext = 'surveys' | 'therapeutic_surveys' | 'custom_survey';

export const SURVEY_CONTEXT = new InjectionToken<SurveyContext>(
  'SurveyContext'
);

const genericSurveysServices: Record<
  SurveyContext,
  new () => GenericSurveysService
> = {
  surveys: SurveysService,
  therapeutic_surveys: TherapeuticSurveysService,
  custom_survey: CustomSurveysService,
};

export function provideSurveyService(surveyContext: SurveyContext) {
  return [
    { provide: SURVEY_CONTEXT, useValue: surveyContext },
    genericSurveysServices[surveyContext],
  ];
}

@Injectable()
export class ContextAwareSurveysService {
  readonly projectContext = inject(PROJECT_CONTEXT);
  readonly surveyContext = inject(SURVEY_CONTEXT);

  private services = Object.fromEntries(
    Object.entries(genericSurveysServices).map(([context, service]) => [
      context,
      inject(service, { optional: true }),
    ])
  ) as Record<SurveyContext, GenericSurveysService | null>;

  getService() {
    const service = this.services[this.surveyContext];
    if (!service) {
      throw new LogicError('No context found for our survey service');
    }
    return service;
  }
}
