import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TrackingSheetAnswer } from '../../models';
import { AuthService } from '../../services';
import { SnackbarService } from '../../services/snackbar.service';
import { filterNil, selectRouteParams } from '../../store';
import { catchSwitchMapError } from '../../utils/catch-switch-map-error';
import { surveyAction } from '../survey';
import { hasPendingChanges, surveyFeature } from '../survey/survey.selectors';
import { trackingSheetAction } from './tracking-sheet.actions';
import { TrackingSheetResources } from './tracking-sheet.resources';

@Injectable()
export class TrackingSheetEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private resources = inject(TrackingSheetResources);
  private snackbar = inject(SnackbarService);
  private router = inject(Router);
  private authService = inject(AuthService);

  loadSurvey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trackingSheetAction.loadTrackingSheet),
      concatLatestFrom(() => [
        this.store.pipe(select(selectRouteParams('userId'))),
        this.store.pipe(select(selectRouteParams('responseId'))),
      ]),
      switchMap(([, userId, responseId]) => {
        return combineLatest([
          of(userId),
          of(responseId),
          this.resources.getSurveyData(userId as string, responseId as string),
          this.resources.getUser(userId as string),
        ]);
      }),
      switchMap(
        ([userId, responseId, { surveyAnswer, surveyTemplate }, user]) => {
          return of(
            surveyAction.loadSurveySuccess({
              surveyTemplate,
              surveyAnswer,
              userId,
              responseId,
            }),
            trackingSheetAction.loadUserSuccess({ user })
          );
        }
      ),
      catchSwitchMapError((error: Error) =>
        trackingSheetAction.handleError({ error })
      )
    );
  });

  onChangePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trackingSheetAction.changePage),
      concatLatestFrom(() => [
        this.store.select(surveyFeature.selectIsSubmitted),
        this.store.select(hasPendingChanges),
      ]),
      map(([, isSubmitted, hasPendingChanges]) => {
        return !isSubmitted
          ? trackingSheetAction.saveProgress({ createRevision: false })
          : hasPendingChanges
          ? trackingSheetAction.saveProgress({ createRevision: true })
          : trackingSheetAction.noChange();
      })
    )
  );

  saveProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        trackingSheetAction.saveProgress,
        trackingSheetAction.submitAnswer
      ),
      concatLatestFrom(() => [
        this.store.select(surveyFeature.selectUserId).pipe(filterNil()),
        this.store.select(surveyFeature.selectResponseId).pipe(filterNil()),
        this.store.select(surveyFeature.selectSurveyAnswer).pipe(filterNil()),
        this.authService.user$,
      ]),
      switchMap(([action, userId, responseId, surveyAnswer, auth]) => {
        const answer: TrackingSheetAnswer = {
          ...surveyAnswer,
          coachId: auth!.uid,
        };
        const createRevision =
          action.type === trackingSheetAction.submitAnswer.type ||
          action.createRevision;
        return combineLatest([
          of(action),
          of(createRevision),
          this.resources.updateSurveyAnswer(userId, responseId, answer),
          createRevision
            ? this.resources.createRevision(userId, responseId, answer)
            : EMPTY,
        ]);
      }),
      map(([action, withRevision]) =>
        action.type === trackingSheetAction.submitAnswer.type
          ? trackingSheetAction.submitAnswerSuccess({ withRevision })
          : trackingSheetAction.saveProgressSuccess({ withRevision })
      ),
      catchSwitchMapError((error: Error) => surveyAction.handleError({ error }))
    )
  );

  commitResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        trackingSheetAction.saveProgressSuccess,
        trackingSheetAction.submitAnswerSuccess
      ),
      map(() => surveyAction.commitResponse())
    )
  );

  saveProgressSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackingSheetAction.saveProgressSuccess),
        tap(({ withRevision }) => {
          if (withRevision) {
            this.snackbar.success(
              `Feuille de suivie modifiée avec succès, avec révision.`
            );
          }
        })
      ),
    { dispatch: false }
  );

  submitAnswerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackingSheetAction.submitAnswerSuccess),
        tap(() => this.router.navigate(['/tracking-sheet/successful'])),
        tap(({ withRevision }) =>
          this.snackbar.success(
            withRevision
              ? `Feuille de suivie modifiée avec succès, avec révision.`
              : `Feuille de suivie enregistrée avec succès.`
          )
        )
      ),
    { dispatch: false }
  );

  errorHandler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trackingSheetAction.handleError),
      map(({ error }) => surveyAction.handleError({ error }))
    )
  );
}
