import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { SurveyLayoutComponent } from '../../components';
import { SurveyComponent } from '../survey';
import { surveyFeature } from '../survey/survey.selectors';
import { trackingSheetAction } from './tracking-sheet.actions';
import { TrackingSheetFeature } from './tracking-sheet.selectors';

@Component({
  selector: 'lib-tracking-sheet',
  templateUrl: './tracking-sheet.component.html',
  styleUrls: ['./tracking-sheet.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgIf, SurveyComponent, SurveyLayoutComponent],
})
export class TrackingSheetComponent {
  private store = inject(Store);

  vm$ = combineLatest({
    template: this.store.select(surveyFeature.selectSurveyTemplate),
    user: this.store.select(TrackingSheetFeature.selectUser),
  });

  onSurveyLoad(): void {
    this.store.dispatch(trackingSheetAction.loadTrackingSheet());
  }

  onPageChange() {
    this.store.dispatch(trackingSheetAction.changePage());
  }

  onSubmit() {
    this.store.dispatch(trackingSheetAction.submitAnswer());
  }
}
