import { Injectable } from '@angular/core';
import { OfflineError } from '../../../errors';
import { isFirebaseUnavailableError } from '../../../firebase';
import { logger } from '../../../utils';
import { ErrorHandlerMapper } from '../error-handler.models';

@Injectable({ providedIn: 'root' })
export class FirebaseUnavailableMapper
  implements ErrorHandlerMapper<OfflineError>
{
  mapError(error: any) {
    if (isFirebaseUnavailableError(error)) {
      logger.error(error?.message, error);
      return new OfflineError(undefined, { error });
    }
    return error;
  }
}
