import { Unsafe, User } from '../../models';

export function parseUser(data: Unsafe<User>) {
  const user: User = {
    ...data,
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
  } as User;
  return user;
}
