import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG } from '../../core';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';
import { PROJECT_CONTEXT } from '../../services/surveys';
import { filterNil, selectRouteParams } from '../../store';
import { sendToMobile } from '../../utils';
import { catchSwitchMapError } from '../../utils/catch-switch-map-error';
import { surveyAction } from '../survey/survey.actions';
import { surveyFeature } from '../survey/survey.selectors';
import { simpleSurveyAction } from './simple-survey.actions';
import { SimpleSurveyResources } from './simple-survey.resources';

@Injectable()
export class SimpleSurveyEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private resources = inject(SimpleSurveyResources);
  private authService = inject(AuthService);
  private snackbar = inject(SnackbarService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private isDeveloperMode = inject(ENVIRONMENT_CONFIG).isDeveloperMode;
  private projectContext = inject(PROJECT_CONTEXT);

  loadSimpleSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(simpleSurveyAction.loadSimpleSurvey),
      concatLatestFrom(() => [
        this.store.pipe(select(selectRouteParams('userId'))),
        // TODO: we need param reference too
        this.store.pipe(select(selectRouteParams('responseId'))),
      ]),
      switchMap(([, userId, responseId]) =>
        combineLatest([
          of(userId as string),
          of(responseId as string),
          this.resources.getSurveyData(userId as string, responseId as string),
          this.authService.claims$.pipe(take(1)),
        ])
      ),
      map(([userId, responseId, { surveyTemplate, surveyAnswer }, claims]) => {
        const isCoach = !!claims['coach'];

        if (surveyAnswer.completionReason === 'expired') {
          return simpleSurveyAction.showExpired();
        }
        const isDisabled =
          this.projectContext === 'medical'
            ? surveyAnswer.isFinished || (isCoach && !this.isDeveloperMode)
            : !isCoach && surveyAnswer.isFinished;
        return surveyAction.loadSurveySuccess({
          surveyTemplate,
          surveyAnswer,
          userId,
          responseId,
          isDisabled,
        });
      }),
      catchSwitchMapError((error: Error) => surveyAction.handleError({ error }))
    )
  );

  showExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(simpleSurveyAction.showExpired),
        tap(() => this.router.navigate(['/survey/finished']))
      ),
    { dispatch: false }
  );

  saveAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(simpleSurveyAction.saveProgress, simpleSurveyAction.submitAnswer),
      concatLatestFrom(() => [
        this.store.select(surveyFeature.selectIsDisabled).pipe(filterNil()),
        this.store.select(surveyFeature.selectUserId).pipe(filterNil()),
        this.store.select(surveyFeature.selectResponseId).pipe(filterNil()),
        this.store.select(surveyFeature.selectSurveyAnswer).pipe(filterNil()),
      ]),
      filter(([, isDisabled]) => !isDisabled),
      switchMap(([action, _isDisabled, userId, responseId, surveyAnswer]) =>
        combineLatest([
          of(action),
          this.resources.updateSurveyAnswer(userId, responseId, surveyAnswer),
        ])
      ),
      map(([action]) => {
        return action.type === simpleSurveyAction.submitAnswer.type
          ? simpleSurveyAction.submitAnswerSuccess()
          : simpleSurveyAction.saveProgressSuccess();
      }),
      catchSwitchMapError((error: Error) => surveyAction.handleError({ error }))
    )
  );

  commitResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        simpleSurveyAction.saveProgressSuccess,
        simpleSurveyAction.submitAnswerSuccess
      ),
      map(() => surveyAction.commitResponse())
    )
  );

  submitAnswerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(simpleSurveyAction.submitAnswerSuccess),
        tap(() => this.router.navigate(['/survey/successful'])),
        tap(() => sendToMobile('Terminer')),
        switchMap(() =>
          this.store.pipe(select(selectRouteParams('permission'))).pipe(take(1))
        ),
        tap((permission) => {
          if (permission) {
            this.snackbar.success(`Questionnaire envoyé avec succès`);
            setTimeout(() => window.close(), 1000);
          }
        })
      ),
    { dispatch: false }
  );
}
