import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SurveyLayoutComponent } from '../../components';
import { SurveyComponent } from '../survey';
import { customSurveyAction } from './custom-survey.actions';

@Component({
  selector: 'lib-custom-survey',
  templateUrl: './custom-survey.component.html',
  styleUrls: ['./custom-survey.component.scss'],
  standalone: true,
  imports: [SurveyComponent, SurveyLayoutComponent],
})
export class CustomSurveyComponent {
  private store = inject(Store);

  onSurveyLoad() {
    this.store.dispatch(customSurveyAction.loadCustomSurvey());
  }

  onSubmit() {
    this.store.dispatch(customSurveyAction.submitAnswer());
  }
}
