import { ErrorHandler, Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomError } from '../../errors';
import { logger } from '../../utils/logger';
import { SentryService } from '../sentry.service';
import { SnackbarService } from '../snackbar.service';
import { ERROR_HANDLER_MAPPER } from './error-handler.provider';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  private snackbar = inject(SnackbarService);
  private sentry = inject(SentryService, { optional: true });
  private mappers = inject(ERROR_HANDLER_MAPPER);

  private _lastError = new BehaviorSubject<string | undefined>(undefined);
  readonly lastError = this._lastError.asObservable();

  handleError(error: any) {
    if (error.promise && error.rejection) {
      error = error.rejection;
    }

    for (const mapper of this.mappers) {
      error = mapper.mapError(error);
    }
    if (!error) {
      return;
    }

    if (this.shouldReport(error)) {
      this.doReport(error);
    }

    if (this.shouldDisplay(error)) {
      const message = this.doDisplay(error);
      this.setLastError(message);
    } else {
      this.setLastError(undefined);
    }
  }

  private setLastError(message: string | undefined) {
    this._lastError.next(message);
  }

  private shouldReport(_error: any) {
    const parts = window?.location?.pathname?.split('/') ?? [];
    if (parts.includes('ide')) {
      // Don't report anything on IDE
      return false;
    }
    return true;
  }

  private shouldDisplay(_error: any) {
    return true;
  }

  private doReport(error: any) {
    this.sentry?.handleError(error);
  }

  private doDisplay(error: any) {
    logger.error('handleError', error);
    let message: string = error.message;

    this._lastError.next(message);

    if (!(error instanceof CustomError)) {
      message = 'Unexpected error: ' + message;
    }

    error.isWarning
      ? this.snackbar.warning(message, null) // Keep open
      : this.snackbar.error(message);

    return message;
  }
}
