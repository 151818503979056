import { createActionGroup, emptyProps } from '@ngrx/store';

export const customSurveyAction = createActionGroup({
  source: 'Custom survey',
  events: {
    'load custom survey': emptyProps(),

    // don't save progress until last submit

    'submit answer': emptyProps(),
    'submit answer success': emptyProps(),
  },
});
