import { provideEffects } from '@ngrx/effects';
import { ContextAwareSurveysService } from '../../services/surveys';
import { analyticsProvider } from '../analytics';
import { surveyProvider } from '../survey';
import { CustomSurveyEffects } from './custom-survey.effects';
import { CustomSurveyResources } from './custom-survey.resources';

export function customSurveyProvider() {
  return [
    provideEffects(CustomSurveyEffects),
    surveyProvider(),
    analyticsProvider(),
    ContextAwareSurveysService,
    CustomSurveyResources,
  ];
}
