import { Injectable } from '@angular/core';
import {
  CollectionReference,
  collection,
  setDoc,
} from '@angular/fire/firestore';
import { doc } from '@angular/fire/firestore';
import { pick } from 'lodash-es';
import { AnswerRevision, TrackingSheetAnswer } from '../../models';
import { logger } from '../../utils';
import { GenericSurveysService } from './generic-surveys.service';

@Injectable()
export class TrackingSheetsService extends GenericSurveysService<TrackingSheetAnswer> {
  constructor() {
    super({
      surveys: 'tracking_sheets',
      surveys_data: 'tracking_sheets_data',
      responses: 'tracking_responses',
    });
  }

  protected revisionsCollection(userId: string, responseId: string) {
    return collection(
      this.userAnswerDoc(userId, responseId),
      'tracking_revisions'
    ) as CollectionReference<AnswerRevision>;
  }

  async createAnswerRevision(
    userId: string,
    responseId: string,
    revision: AnswerRevision
  ) {
    const id = Date.now().toString();
    const revisionRef = doc(this.revisionsCollection(userId, responseId), id);
    logger.log('createAnswerRevision', { userId, responseId, id });
    await setDoc(revisionRef, revision);
    return revisionRef;
  }
}

export function buildRevision(answer: TrackingSheetAnswer) {
  return pick(answer, [
    'coachId',
    'modificationDate',
    'responses',
  ]) as AnswerRevision;
}
