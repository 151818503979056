import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequestError } from '../../../errors';
import { logger } from '../../../utils';
import { ErrorHandlerMapper } from '../error-handler.models';

@Injectable({ providedIn: 'root' })
export class AngularHttpErrorMapper implements ErrorHandlerMapper {
  mapError(error: any, message?: string) {
    if (error instanceof HttpErrorResponse) {
      // HttpErrorResponse does not inherit from Error so we need to wrap it for Sentry
      logger.error(error?.message, error);
      throw new HttpRequestError(
        message ?? `Une erreur est survenue lors de l'appel du service.`,
        { error }
      );
    }
    return error;
  }
}
