<lib-survey-layout>
  <lib-survey
    [options]="{
      hideHeader: true,
      submitOnLastQuestion: true,
      submitLabel: 'Accéder aux résultats'
    }"
    (surveyLoad)="onSurveyLoad()"
    (submitted)="onSubmit()"
    (resized)="onResize()"
  >
    <ng-template
      #introPage
      *ngIf="(vm$ | async)?.template as template"
      libSurveyIntro
    >
      <div class="survey-page">
        <div class="survey-page__content">
          <h3>{{ template.subtitle }}</h3>

          <p class="lib-eligibility-survey__description">
            {{ template.description }}
          </p>

          <div class="lib-eligibility-survey__button-row">
            <button
              mat-raised-button
              color="primary"
              class="btn action-button continuer-button add"
              (click)="nextPage()"
            >
              Démarrer
            </button>
          </div>

          <div class="lib-eligibility-survey__policy">
            <div class="lib-eligibility-survey__policy-title">
              Avertissement
            </div>
            <p>
              Le bracelet Remedee Well est conforme aux exigences essentielles
              de la Directive 2014/53/UE du 16 avril 2014 relative à la mise sur
              le marché des équipements radioélectriques. Cela signifie que son
              usage, dans des conditions normales d’utilisation, est sécurisé et
              un plan de gestion des risques a été mis en place par Remedee
              Labs. Pour tout signalement, vous pouvez contacter Remedee Labs à
              contact@remedee.com. En revanche, il n’a pas reçu de marquage CE
              médical au sens du Règlement (UE) 2017/745 du 5 avril 2017 relatif
              aux dispositifs médicaux. Cela signifie que son utilisation est
              strictement réservée à une finalité personnelle de bien-être.
            </p>
          </div>

          <div class="lib-eligibility-survey__policy">
            <div class="lib-eligibility-survey__policy-title">
              Notre politique de gestion de données en quelques mots.
            </div>
            <p>
              {{ template.ending_subtitle }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </lib-survey>
</lib-survey-layout>
