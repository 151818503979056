import { inject } from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  query,
  orderBy,
  CollectionReference,
  getDocs,
} from '@angular/fire/firestore';
import { Timestamp, setDoc } from '@angular/fire/firestore';
import { NotFoundError, OfflineError } from '../../errors';
import { SurveyAnswer, SurveyReference, SurveyTemplate } from '../../models';
import { logger } from '../../utils/logger';

export interface SurveysCollectionsConfig {
  surveys: string;
  surveys_data: string;
  responses: string;
}

export abstract class GenericSurveysService<
  Answer extends SurveyAnswer = SurveyAnswer
> {
  protected firestore = inject(Firestore);

  constructor(protected config: SurveysCollectionsConfig) {}

  async getSurveyTemplate(reference: SurveyReference, version?: string) {
    const { surveys } = this.config;
    const templates = await getDocs(
      query(
        collection(
          this.firestore,
          `${surveys}/${reference}/versions`
        ) as CollectionReference<SurveyTemplate>,
        orderBy('version', 'desc')
      )
    );

    if (templates.empty) {
      if (templates.metadata.fromCache) {
        throw new OfflineError();
      }
      throw new NotFoundError(
        `Ce formulaire est invalide, ou il manque son identifiant: '${reference}'.`
      );
    }

    if (version) {
      // Get latest template that match version major.minor
      const [major, minor] = version.split('.'); // Ignore patch
      const template = templates.docs.find((template) => {
        const [maj, min] = template.data().version.split('.');
        return major == maj && minor == min;
      });

      if (!template) {
        throw new NotFoundError(
          `Ce formulaire est invalide: '${reference}:${version}'.`
        );
      }

      return template;
    }

    // Get the most recent
    return templates.docs[0];
  }

  protected userAnswersCollection(userId: string) {
    const { surveys_data, responses } = this.config;
    return collection(
      this.firestore,
      `${surveys_data}/${userId}/${responses}`
    ) as CollectionReference<Answer>;
  }

  userAnswerDoc(userId: string, responseId?: string) {
    return responseId
      ? doc(this.userAnswersCollection(userId), responseId)
      : doc(this.userAnswersCollection(userId));
  }

  async getSurveyAnswer(userId: string, responseId: string) {
    const answer = await getDoc(this.userAnswerDoc(userId, responseId));
    if (!answer.exists()) {
      throw new NotFoundError("Le questionnaire n'a pas pu être trouvé.");
    }
    return answer;
  }

  async createSurveyAnswer(userId: string, answer: Answer) {
    const answerRef = doc(this.userAnswersCollection(userId));
    logger.log('createSurveyAnswer', { userId, answerId: answerRef.id });
    await setDoc(answerRef, { ...answer, id: answerRef.id });
    answer.id = answerRef.id;
    return answerRef;
  }

  async updateSurveyAnswer(userId: string, responseId: string, data: Answer) {
    const answerRef = this.userAnswerDoc(userId, responseId);
    logger.log('updateSurveyAnswer', answerRef.path);
    await setDoc(answerRef, data);
  }

  initSurveyAnswer(survey: SurveyTemplate) {
    const now = Timestamp.now();

    const answer: SurveyAnswer = {
      id: '',
      creationDate: now,
      modificationDate: now,
      isFinished: false,
      pageCount: survey.pages.length,
      progressPage: 0,
      ref: survey.reference,
      responses: {},
      shortTitle: survey.short_title,
      version: survey.version,
    };

    return answer as Answer;
  }
}
