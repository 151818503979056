import { Injectable, inject } from '@angular/core';
import { SurveyAnswer } from '../../models/surveys';
import { ContextAwareSurveysService } from '../../services/surveys';

@Injectable()
export class CustomSurveyResources {
  private service = inject(ContextAwareSurveysService).getService();

  async getSurveyData(reference: string, userId: string) {
    const templateDoc = await this.service.getSurveyTemplate(reference);

    const surveyTemplate = templateDoc.data();
    // TODO: we may want to throw an exception if survey is not planned to be a custom-survey

    const surveyAnswer = this.service.initSurveyAnswer(surveyTemplate);

    // Assign an id but don't create it until the last submit
    const responseId = this.service.userAnswerDoc(userId).id;
    surveyAnswer.id = responseId;

    return { surveyTemplate, surveyAnswer, responseId };
  }

  async saveSurveyAnswer(
    userId: string,
    responseId: string,
    answer: SurveyAnswer
  ) {
    await this.service.updateSurveyAnswer(userId, responseId, answer);
  }
}
