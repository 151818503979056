import { Injectable, inject } from '@angular/core';
import { getIdTokenResult } from '@angular/fire/auth';
import { CanActivateChildFn } from '@angular/router';
import { ErrorHandlerService } from '../services';
import { logger } from '../utils';
import { ForbiddenError, UnauthorizedError } from './errors';
import { SharedGuard } from './shared.guard';

@Injectable({
  providedIn: 'root',
})
export class CoachGuardService extends SharedGuard {
  private errorHandler = inject(ErrorHandlerService);

  async canActivate() {
    logger.log('CoachGuard canActivate?');

    const user = await this.getCurrentUser();
    if (!user) {
      logger.log('CoachGuard unauthorized');
      throw new UnauthorizedError();
    }

    const idToken = await getIdTokenResult(user);
    if (!idToken) {
      logger.log('no idToken'); // should not happen if isAuthenticated
      throw new UnauthorizedError();
    }

    const claims = idToken.claims;
    if (claims['coach'] || claims['admin']) {
      logger.log('user has super claims', claims);
      return true;
    }

    logger.log('CoachGuard forbidden');
    throw new ForbiddenError();
  }

  async canActivateWithRedirect() {
    try {
      return await this.canActivate();
    } catch (error) {
      this.redirectOnError(error);
      this.errorHandler.handleError(error);
      throw error;
    }
  }
}

export const CoachGuard: CanActivateChildFn = () =>
  inject(CoachGuardService).canActivate();

export const CoachGuardWithRedirect: CanActivateChildFn = () =>
  inject(CoachGuardService).canActivateWithRedirect();
