import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { TrackingSheetsService } from '../../services/surveys';
import { UserService } from '../../services/user.service';
import { analyticsProvider } from '../analytics';
import { surveyProvider } from '../survey';
import { TrackingSheetEffects } from './tracking-sheet.effects';
import { TrackingSheetResources } from './tracking-sheet.resources';
import { TrackingSheetFeature } from './tracking-sheet.selectors';

export function trackingSheetProvider() {
  return [
    provideState(TrackingSheetFeature),
    provideEffects(TrackingSheetEffects),
    surveyProvider(),
    analyticsProvider(),
    TrackingSheetResources,
    TrackingSheetsService,
    UserService,
  ];
}
