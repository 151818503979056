import { provideEffects } from '@ngrx/effects';
import { AuthService } from '../../services';
import { ContextAwareSurveysService } from '../../services/surveys';
import { analyticsProvider } from '../analytics';
import { surveyProvider } from '../survey';
import { SimpleSurveyEffects } from './simple-survey.effects';
import { SimpleSurveyResources } from './simple-survey.resources';

export function simpleSurveyProvider() {
  return [
    provideEffects(SimpleSurveyEffects),
    surveyProvider(),
    analyticsProvider(),
    ContextAwareSurveysService,
    SimpleSurveyResources,
    AuthService,
  ];
}
