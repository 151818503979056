import { Injectable } from '@angular/core';
import { GenericSurveysService } from './generic-surveys.service';

@Injectable()
export class CustomSurveysService extends GenericSurveysService {
  constructor() {
    super({
      surveys: 'surveys', // same as usual surveys
      surveys_data: 'custom_surveys_data',
      responses: 'custom_surveys_responses',
    });
  }
}
