import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { from } from 'rxjs';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { selectRouteParams } from '../../store';
import { catchSwitchMapError } from '../../utils/catch-switch-map-error';
import { surveyAction } from '../survey/survey.actions';
import { surveyFeature } from '../survey/survey.selectors';
import { customSurveyAction } from './custom-survey.actions';
import { CustomSurveyResources } from './custom-survey.resources';

@Injectable()
export class CustomSurveyEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private resources = inject(CustomSurveyResources);
  private router = inject(Router);

  loadCustomSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customSurveyAction.loadCustomSurvey),
      concatLatestFrom(() => [
        this.store.pipe(select(selectRouteParams('userId'))),
        this.store.pipe(select(selectRouteParams('reference'))),
      ]),
      switchMap(([, userId, reference]) =>
        from(
          this.resources.getSurveyData(reference as string, userId as string)
        ).pipe(
          map(({ surveyTemplate, surveyAnswer, responseId }) => {
            return surveyAction.loadSurveySuccess({
              surveyTemplate,
              surveyAnswer,
              responseId,
              userId,
            });
          })
        )
      ),
      catchSwitchMapError((error: Error) => surveyAction.handleError({ error }))
    )
  );

  saveAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(customSurveyAction.submitAnswer),
      concatLatestFrom(() => [
        this.store.select(surveyFeature.selectSurveyState),
      ]),
      exhaustMap(([, { userId, responseId, surveyAnswer }]) =>
        from(
          this.resources.saveSurveyAnswer(userId!, responseId!, surveyAnswer!)
        ).pipe(map(() => customSurveyAction.submitAnswerSuccess()))
      ),
      catchSwitchMapError((error: Error) => {
        return surveyAction.handleError({ error });
      })
    )
  );

  submitAnswerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customSurveyAction.submitAnswerSuccess),
        tap(() => this.router.navigate(['/custom-survey/successful']))
      ),
    { dispatch: false }
  );
}
