<ng-container *ngIf="vm$ | async as vm; else loaderBlock">
  <div class="lib-tracking-sheet__header">
    <h2 class="lib-tracking-sheet__username">
      {{ vm.user?.firstName ?? '' }} {{ vm.user?.lastName ?? '' }}
    </h2>
    <h3 class="lib-tracking-sheet__template-title">
      {{ vm.template?.short_title }}
    </h3>
  </div>
  <lib-survey-layout>
    <lib-survey
      class="lib-tracking-sheet__survey"
      (surveyLoad)="onSurveyLoad()"
      (pageChanged)="onPageChange()"
      (submitted)="onSubmit()"
      username="{{ vm.user?.firstName ?? '' }} {{ vm.user?.lastName ?? '' }}"
    >
    </lib-survey>
  </lib-survey-layout>
</ng-container>
<ng-template #loaderBlock>
  <div class="loader"></div>
</ng-template>
