import { Injectable, inject } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import {
  CollectionReference,
  collection,
  doc,
  getDoc,
} from '@angular/fire/firestore';
import { NotFoundError } from '../errors';
import { User } from '../models';

@Injectable()
export abstract class UserService {
  protected firestore = inject(Firestore);

  protected getUsersCollection() {
    return collection(this.firestore, 'users') as CollectionReference<User>;
  }

  protected getUserDoc(userId: string) {
    return doc(this.getUsersCollection(), userId);
  }

  async getUser(userId: string) {
    const user = await getDoc(this.getUserDoc(userId));
    if (!user.exists()) {
      throw new NotFoundError("Cet utilisateur n'existe pas.");
    }
    return user;
  }
}
