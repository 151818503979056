import { Injectable, inject } from '@angular/core';
import { TrackingSheetAnswer } from '../../models';
import { TrackingSheetsService, buildRevision } from '../../services/surveys';
import { UserService } from '../../services/user.service';
import { parseSurveyAnswer } from '../survey/survey.parser';
import { parseUser } from './tracking-sheet.parser';

@Injectable()
export class TrackingSheetResources {
  private service = inject(TrackingSheetsService);
  private userService = inject(UserService);

  async getSurveyData(userId: string, responseId: string) {
    const service = this.service;
    const answerSnap = await service.getSurveyAnswer(userId, responseId);
    const surveyAnswer = parseSurveyAnswer(answerSnap.data());

    const surveyTemplate = (
      await service.getSurveyTemplate(surveyAnswer.ref, surveyAnswer.version)
    ).data();

    return { surveyAnswer, surveyTemplate };
  }

  async updateSurveyAnswer(
    userId: string,
    responseId: string,
    answer: TrackingSheetAnswer
  ) {
    await this.service.updateSurveyAnswer(userId, responseId, answer);
  }

  async createRevision(
    userId: string,
    responseId: string,
    answer: TrackingSheetAnswer
  ) {
    const revision = buildRevision(answer);
    await this.service.createAnswerRevision(userId, responseId, revision);
  }

  async getUser(userId: string) {
    const userSnap = await this.userService.getUser(userId);
    return parseUser(userSnap.data());
  }
}
